





































































































































































































































































import { ElForm } from "element-ui/types/form";
import { Component, Vue } from "vue-property-decorator";

import {
  getPageList,
  documentAdd,
  documentUpdate,
  documentDelete,
} from "@/serve/document/document";

import {
  getTenantData,
  uploadImg,
} from "@/serve/medicalAppointment/institutionManage";

interface FormData {
  id: string;

  name: string;
  description: string;

  sort: number;
  url: any;
  category: string;
}
interface DiseaseStandardList {
  comment: string;
  highestScore: number | string;
  lowestScore: number | string;
  remark: string;
  riskLevel: string;
  [index: string]: any;
}
interface StandarData {
  size?: number;
  current?: number;
  keyword?: string;
  [propName: string]: any;
}
@Component({
  name: "EvaluatingStandard",
  filters: {},
})
export default class EvaluatingStandard extends Vue {
  public currentPage4 = 1;
  public dialogFormVisible = false;
  public formLabelWidth = "120px";
  public form = {
    illness: "",
    category: "",
  };
  public fileList: any = [];
  public fileListQrCode: any = [];

  public checkSign = "";
  public checkSignId = 0;
  public options = [
    { value: "疾病", label: "疾病" },
    { value: "生活习惯", label: "生活习惯" },
    { value: "饮食健康", label: "饮食健康" },
    { value: "心理压力", label: "心理压力" },
  ];

  public activeName = "低";
  public formData: FormData = {
    id: "",
    name: "",
    url: [],
    category: "Word",
    description: "",
    sort: 0,
  };
  public tableData = [];
  public formDatas = {
    size: 10,
    current: 1,
    keyword: "",
    tenantId: "",
  };
  public tenantName = "";
  public restaurants = [];
  public isAdmin = sessionStorage.getItem("isAdmin");
  public created() {
    this.getDiseaseList();
  }
  public mounted() {
    this.getTenantData();
  }
  public fileType: any = ".doc,.docx";
  public selectChange(e: any) {
    switch (e) {
      case "Word":
        this.fileType = ".doc,.docx";
        break;
      case "PDF":
        this.fileType = ".pdf";

        break;
      case "Excel":
        this.fileType = ".xls,.xlsx";

        break;

      default:
        break;
    }
  }

  public file!: string;
  public fileOldList: any = []; //赋值给表单img字段
  public async handleAdSuccess(res: { file: File }) {
    const imgAdOld = this.formData.url;
    const fileOld = this.fileOldList;
    const imgs = new FormData();
    imgs.append("file", res.file);
    fileOld.push(res.file);
    this.fileOldList = fileOld;
    const file = await uploadImg(imgs);
    this.file = file.data;
    imgAdOld.push(file.data);
    this.formData.url = imgAdOld;
  }
  iconDel(index: any) {
    // file.uid
    const imgDel = this.formData.url;

    const removed = imgDel.splice(index + 1, 1);

    this.formData.url = removed;

    this.fileList = [];
  }

  // 进入页面获取数据
  public async getDiseaseList() {
    this.$changNull(this.formDatas);
    const res = await getPageList(this.formDatas);
    res.data.records.map((item: { url: any }) => {
      item.url = JSON.parse(item.url);
    });
    this.tableData = res.data;
  }
  //  表单提交按钮
  public async handlerSubmit() {
    // 讲字符串转化为数字

    // 第一季表单验证
    (this.$refs["formData"] as ElForm).validate(async (valid: any) => {
      if (valid) {
        if (this.formData.id) {
          const res = await documentUpdate(this.formData);
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "更新成功!",
            });
            this.dialogFormVisible = false;
            this.getDiseaseList();
          }
        } else {
          const res = await documentAdd(this.formData);
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.dialogFormVisible = false;
            this.getDiseaseList();
          }
        }
      } else {
        return false;
      }
    });
  }

  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.formDatas.tenantId = e.tId;
    else this.formDatas.tenantId = "";
  }
  // 查看资料
  public selectRow(item: any) {
    const link = document.createElement("a");
    link.href = item.url;
    link.download = item.name; // 指定下载的文件名
    document.body.appendChild(link);
    link.click();
    // 清理
    document.body.removeChild(link);
  }
  // 编辑按钮
  public async editRow(item: any): Promise<void> {
    this.checkSign = "修改医生信息";

    const formDataNew: any = {
      id: item.id,
      name: item.name,
      url: item.url,
      category: item.category,
      description: item.description,
      sort: item.sort,
    };
    this.formData = formDataNew;
    this.dialogFormVisible = true;
  }
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  // 筛选按钮
  public onSubmit(): void {
    this.getDiseaseList();
  }
  // 点击新增按钮
  public addSign() {
    this.dialogFormVisible = true;
    this.checkSign = "新增资料";
    this.formData = {
      id: "",
      name: "",
      url: [],
      category: "Word",
      description: "",
      sort: 0,
    };
    this.fileListQrCode = [];
    this.fileList = [];
  }
  // 删除按钮
  public deleteRow(row: any, id: any): void {
    (this as any).$messageBox
      .confirm(`您确定是否删除医生姓名为${row.name}的这一条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(async () => {
        const params: any = {
          id,
        };
        const res = await documentDelete(params);
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getDiseaseList();
        }
      });
  }
  // 取消按钮
  public hanlderCancel() {
    this.dialogFormVisible = false;
    (this.$refs["formData"] as ElForm).resetFields();
    (this.$refs["StandardList0"] as ElForm[])[0].resetFields();
    (this.$refs["StandardList1"] as ElForm[])[0].resetFields();
    (this.$refs["StandardList2"] as ElForm[])[0].resetFields();
    this.activeName = "低";
  }
  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.formDatas.size = val;
    this.getDiseaseList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.formDatas.current = val;
    this.getDiseaseList();
  }
  // 重置按钮
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();
    this.tenantName = "";
    this.formDatas.tenantId = "";
    this.currentPage4 = 1;
    this.getDiseaseList();
  }
  public handleClick() {
    console.log(1);
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.formDatas.tenantId = "";
  }
}
