import request from '../../utils/request'

//附件文档 分页列表
export function getPageList(data: any): Promise<any> {
  return request({
    method: 'GET',
    data,
    type: 'params',
    url: 'manage/document/pageList',
  })
}
// 附件文档详情
export function getPageListDetail(data: any): Promise<any> {
  return request({
    method: 'GET',
    data,
    type: 'params',
    url: 'manage/document/detail',
  })
}
// 附件文档新增
export function documentAdd(data: any): Promise<any> {
  return request({
    method: 'POST',
    data,
    url: 'manage/document/add',
  })
}


// 附件文档修改
export function documentUpdate(data: any): Promise<any> {
  return request({
    method: 'POST',
    data,
    url: 'manage/document/update',
  })
}
// 附件文档删除
export function documentDelete(data: any): Promise<any> {
  return request({
    method: 'POST',
    data,
    url: 'manage/document/delete',
  })
}

